@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~sweetalert2/dist/sweetalert2.css';
@import "flatpickr/dist/themes/material_green.css";

@layer base {
  .nav-item-active {
    @apply bg-gray-50 bg-opacity-20;
  }
  .nav-item {
    @apply hover:bg-gray-50 hover:bg-opacity-20;
  }

  .nav-footer-item-active {
    @apply text-blue-500;
  }


  
}

